#addUser {
  height: 38px;
  width: 95px;
  opacity: 0.7;
  color: #000;
  font-family: Hind, 'sans-serif';
  font-size: 24px;
  line-height: 38px;
  text-align: center;
}

#addUserIcon {
  height: 20px;
  width: 20px;
  margin-left: 15px;
  margin-bottom: 5px;
}

.modalHeader {
  margin-top: 35px;
  margin-left: 60px;
  margin-bottom: 11px;
}

.modalFields {
  display: flex;
  margin-left: 60px;
}

.inputBoxModal {
  box-sizing: border-box;
  height: 36px;
  width: 216px;
  border-radius: 5px;
  background-color: #edf3fc;
  border: 0.5px solid #cedcf2 !important;
}

.inputBoxModal:focus {
  box-shadow: none;
}

.fieldLabel {
  opacity: 0.6;
  color: #000;
  font-family: Hind;
  font-size: 12px;
  line-height: 19px;
  // margin-top: 11px;
}

.userInputContainer {
  margin-left: 24px;
}

#userInput:focus {
  box-shadow: none;
}

#dropdownModalBtn {
  box-sizing: border-box;
  height: 36px;
  width: 180px;
  border-radius: 5px;
  background-color: #fff;
  color: #000;
  border: 1.5px solid #cedcf2;
}

#dropdownModalBtn:focus {
  box-shadow: none;
}

.filterBtnModal {
  height: 36px;
  width: 120px;
  border-radius: 5px;
  background-color: #4f76db;
  color: #fff;
  font-family: Hind;
  font-size: 12px;
  font-weight: 700;
  line-height: 19px;
  border: none;
}

.filterBtnModal:disabled {
  background-color: #e6e7ea;
  line-height: 19px;
  text-align: center;
  border: none;
  color: #000;
  font-weight: normal;
  opacity: 0.6;
}

.dropdown-menu {
  overflow-y: auto;
}

@import '../../../sass/variables.scss';

.infoBox {
  border-radius: 5px;
  border: 1px solid $highlightFontColor;
  width: 39rem;
  background-color: $lightBgColor;
  margin: 0px 1.5rem 1.5rem 0px;
  padding: 1.5rem;

  .infoBoxLabel {
    font-size: 1em;
    color: $fontColor;
    margin-bottom: 1.2em;
  }

  .itemLabel {
    margin-bottom: 1em;
    width: 100%;
    height: 3em;
  }

  .itemLabelBlockseer {
    width: 100%;
    height: 2.5em;
  }

  .container {
    display: flex;
    .item {
      font-size: 0.7em;
      color: #4f4f4f;
      padding: 20px 0px;
      text-align: center;
      width: 6rem;
      height: 100%;

      .itemValue {
        font-size: 2.2em;
      }

      .colorBar {
        width: 3.5rem;
        border-radius: 5px;
        height: 0.5rem;
        margin: 0.5rem auto 0 auto;
      }

      .orange {
        background-color: #ff9933;
      }

      .green {
        background-color: #72d98c;
      }
      .red {
        background-color: #ee5a5a;
      }
      .yellow {
        background-color: #ffd440;
      }
      .gray {
        background-color: #c8c8c8;
      }
    }
  }

  .filterTag {
    font-size: 10px;
    color: $fontColor !important;
    cursor: none;
    font-weight: normal;
    margin: 0px 5px 5px 0px;
    height: 20px;
    line-height: 20px;
    &.customerTag {
      background-color: rgba(88, 81, 217, 0.3);
      border: none;
    }
    .poolTag {
      background-color: rgba(79, 118, 219, 0.3);
      border: none;
    }
  }

  .workerGraph {
    margin-top: 27px;
    background-color: #d9e8f4;
  }
}

@media (max-width: 868px) {
  .container {
    flex-wrap: wrap;
  }
}

//phone portrait
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .infoBox {
    max-width: 21rem;
    margin: 1.5rem auto;
    .container {
      .item {
        width: 5rem;
      }
    }
  }
}

//phone landscape
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .infoBox {
    max-width: 45rem;
    margin: 1.5rem auto;
  }
}

//iPad portrait
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .infoBox {
    max-width: 45rem;
    margin: 1.5rem auto;
  }
}

//Background
$bgColor: #f7f7f7;
$lightBgColor: #fff;
$darkBgColor: #f7f7f7;
$darkerBgColor: #f7f7f7;
$hoverBgColor: #d7e3f5;

//Font
$lightFontColor: rgba(0, 0, 0, 0.4);
$fontColor: rgba(0, 0, 0, 0.6);
$darkFontColor: rgba(0, 0, 0, 0.8);
$boldFontColor: #000;
$highlightFontColor: #4f76db;
$errorFontColor: #ee5a5a;

$fontSize: 14px;
$fontSizeMedium: 15px;
$fontSizeLarge: 25px;
$fontFamily: 'Hind', sans-serif;

//Border
$borderColor: #b1b1b5;
$lightBorderColor: #cedcf2;
$selectedBorderColor: #5c8cdb;
$errorBorderColor: rgba(238, 90, 90, 0.5);

//Icon
$iconSize: 1.2em;
$lightIconColor: #b1b1b5;
$boldIconColor: #fff;

$highlightColor: #80cbc4;

//Chart
$chartContainerWidth: 560px;
$chartContainerHeight: 370px;
$loginWidth: 360px;

//Button
$clearBtnBg: #e6e7ea;

@import '../../sass/variables.scss';
@import '../../sass/misc.scss';

.radio {
  position: relative;
  cursor: pointer;
  line-height: 18px;
  .label {
    position: relative;
    display: block;
    float: left;
    margin: 0px 5px 0px 15px;
    width: 18px;
    height: 18px;
    border: 2px solid $lightIconColor;
    border-radius: 100%;
    -webkit-tap-highlight-color: transparent;
    &:after {
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background: $highlightFontColor;
      transform: scale(0);
      transition: all 0.2s ease;
      opacity: 0.08;
      pointer-events: none;
    }
    &:hover {
      .label:after {
        transform: scale(3.6);
      }
    }
  }
}

input[type='radio']:checked + .label {
  border-color: $highlightFontColor;
  &:after {
    transform: scale(1);
    transition: all 0.4s cubic-bezier(0.35, 0.9, 0.4, 0.9);
    opacity: 1;
  }
}

.labelText[isChecked='true'] {
  color: $highlightFontColor;
  font-weight: bold;
}

.hidden {
  display: none;
}

.radioBtn {
  display: flex;
  margin: 10px 0px 0px 20px;
  flex-direction: row;
}

.portView1[physical-view='true'] {
  display: flex;
  flex-direction: row;
  .portStacks {
    display: flex;
    flex-direction: row;
  }
}
.port {
  margin: 20px 5px 10px 5px;
  .portContent {
    width: 140px;
    display: grid;
    margin: 10px;
    grid-template-columns: repeat(4, 25%);
    padding: 3px;
    border: 0.02px solid $lightFontColor;
    background: $darkerBgColor;
    div {
      display: grid;
      border-radius: 2px;
      margin: 1px;
      text-align: center;
      line-height: 30px;
      font-size: 12px;
      cursor: pointer;
    }
    .portCellBorder {
      border: 1.5px solid $highlightColor;
    }
    .portCell[isclicked='true'] {
      border: 1.5px solid $highlightColor;
    }
    .portCell[isclicked='false'] {
      border-style: none;
      color: black;
    }
  }
}
.portTitle {
  width: 120px;
  margin: 0 auto;
}
.portView1[physical-view='false'] {
  display: flex;
  flex-direction: row;
  .portStacks {
    .port {
      margin: 1px 0px;
      .portContent {
        width: 810px;
        margin-top: 5px;
        grid-template-columns: repeat(25, 1fr);
      }
      .portContent[switch-view='true'] {
        width: 850px;
        grid-template-columns: 31.3% 31.3% 31.3% 4.5%;
        grid-gap: 5px;
        .portCol {
          grid-template-columns: repeat(8, 1fr);
          grid-template-rows: 50% 50%;
          border: 1px solid $lightFontColor;
        }
        .portCol4 {
          grid-template-rows: 50% 50%;
          border: 1px solid $lightFontColor;
        }
      }
    }
  }
}

.portTooltip {
  list-style-type: none;
  width: 250px;
  font-size: $fontSize - 1;
  margin: 120px 20px;
  padding: 15px 20px;
  border-radius: 5px;
  width: 220px;
  z-index: z('tooltip');
}

.portTooltip[hasData='true'] {
  color: $darkFontColor;
  background: $lightBgColor;
  border: 2px solid $highlightFontColor;
}

.portTooltip[hasData='false'] {
  color: $lightFontColor;
  background: $darkerBgColor;
  border: 1px solid $lightFontColor;
}

.portTooltip[physical-view='false'] {
  margin: 120px 0px 120px 20px;
}

@import '../../../sass/variables.scss';

.editCustomerSubMenu {
  border: 1px solid #5184d9;
  position: absolute;
  right: 45px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

@import "./sass/variables.scss";
@import "./sass/buttons.scss";
@import "./sass/chevrons.scss";
@import "./sass/tables.scss";

body {
    background: $darkBgColor;
    color: $lightFontColor;
    font-family: $fontFamily;
}

hr {
    border-top: 1px solid $borderColor;
}

i{
    color: $boldIconColor;
    font-size: $iconSize;
}

p{
    color: $fontColor;
    font-family: $fontFamily;;
}

.p20{
    font-size: 20px;
    margin: 0px;
}

.p12{
    font-size: 12px;
    margin: 0px;
}

#logoDashboard{
    width: 80px;
}

button{
    cursor: pointer;
}

.toastContainer{
    background: #51A7FF !important;
    font-size: $fontSize;
    text-align: center
}

.Toastify__toast {
    min-height: 30px !important;
    width: 240px;
    border-radius: 4px !important;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
@import '../../sass/variables.scss';

.sideBar {
  background-color: $lightBgColor;
  position: fixed;
  border-right: 1px solid $lightBorderColor;
  .sideBarIcon {
    width: 40%;
    height: auto;
  }

  .sideBarIcon[expanded='true'] {
    margin-left: 50px !important;
  }

  h1 {
    text-align: center;
    color: $highlightFontColor;
    font-weight: bold;
  }
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
  min-width: 250px;
}

.sidenav---sidenav-navitem---uwIJ-:hover,
.sidenav---sidenav-navitem---uwIJ-:active,
.sidenav---navitem---9uL5T-:hover {
  background: $hoverBgColor;
}

.sidenav---selected---1EK3y,
.sidenav---highlighted---oUx9u {
  background: $hoverBgColor;
}

.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u
  > .sidenav---navitem---9uL5T::after,
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u
  > .sidenav---navitem---9uL5T::after {
  background: $bgColor;
}

.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-:hover
  > .sidenav---navitem---9uL5T::after {
  background: $bgColor;
}

/* Adjust margin for each item bar */
.sidenav---sidenav-navitem---uwIJ- {
  margin: 5px 6px;
}

//height of each Nav item
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T,
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navicon---3gCRo {
  height: 55px;
  line-height: 55px;
  margin-right: 0px;
}

.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navtext---1AE_f {
  color: $lightFontColor;
  font-size: 18px;
}
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navicon---3gCRo
  > *,
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navtext---1AE_f
  > * {
  color: $lightFontColor;
}

.sidenav---navtext---1AE_f {
  margin-left: 30px;
}

.sidenav---sidenav-navitem---uwIJ-:hover {
  * {
    color: $lightFontColor !important;
  }
}

.sidenav---sidenav-navitem---uwIJ-.sidenav---selected---1EK3y.sidenav---highlighted---oUx9u {
  .sidenav---navtext---1AE_f {
    font-weight: bold;
    color: $highlightFontColor !important;
  }
}

.logoFull {
  width: 220px;
  position: relative;
  top: 40px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

@media (max-width: 1200px) {
  .sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
    width: 100px !important;
    .sidenav---navicon---3gCRo {
      visibility: hidden;
    }
    .sidenav---navtext---1AE_f {
      margin: 0px;
    }
    .sidenav---navicon---3gCRo {
      width: 25% !important;
    }
  }

  .sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
    min-width: 150px;
  }

  .logoFull {
    width: 150px;
  }
}

@media (max-width: 868px) {
  nav {
    display: none;
  }
}

.unsavedPopup {
  background-color: white;
  width: 100%;
  border: 2px solid #4f76db;
  border-radius: 5px;
  text-align: center;
}

.unsavedTitle {
  font-size: 20px;
  padding-top: 0%;
  width: 50%;
  display: inline-block;
  color: #4f76db;
  margin: 3rem auto;
}

.unsavedConfirmation {
  background-color: #f7f7f7;
  color: black;
  padding-bottom: 25px;
}

.unsavedConfirmation p {
  display: inline-block;
  padding-top: 33px;
  width: 50%;
}

.unsaved-buttons {
  bottom: 25px;
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  justify-content: space-around;
}

.unsaved-buttons button {
  height: 4rem;
  width: 30%;
  border: 0 solid white;
}

.unsaved-accept-button {
  background-color: #4f76db;
  color: white;
  border-radius: 5px;
}

.unsaved-cancel-button {
  background-color: #fff;
  color: #4f76db;
  border: 1.5px solid #4f76db;
  border-radius: 5px;
}

@import '../../sass/variables.scss';

.profile {
  display: flex;
  align-items: center;
  width: 100%;
}
.view-profile,
.edit-profile {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: auto;
  border: 1px solid #4f76db;
  background-color: white;
  width: 100%;
  padding: 1rem 2rem;
}

.view-profile {
  height: 420px;
  top: 25vh;
  display: flex;
}

.edit-profile {
  height: 49em;
  top: 15vh;
}

.profile-pic-and-name {
  display: flex;
  flex-direction: column;
  margin: 5rem 5rem;
}

.profile-picture {
  border: 1px solid #4f76db;
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  margin: 0 auto;
}

.picture-overlay {
  display: inline;
  position: absolute;
  // left: 8rem;
  width: 10rem;
  top: 50%;
  transform: translate(-100%, -50%);
  opacity: 0.7;
  background-color: #4f76db;
}

.edit-picture {
  position: absolute;
  left: 208px;
  top: 158px;
  width: 40px;
  height: 40px;
}

.profile-picture-button {
  all: unset;
  cursor: pointer;
  margin: 0 auto;
  position: relative;
}

.edit-pencil {
  width: 20px;
  height: 20px;
  margin-left: 3%;
  margin-bottom: 3%;
  cursor: pointer;
}

.edit-label {
  cursor: pointer;
  font-size: 12px;
}

.account-name {
  text-align: center;
  margin: 1rem auto;
  color: black;
  font-size: 16px;
  max-width: 10rem;
  word-wrap: break-word;
}

.header-label {
  color: black;
  font-size: 16px;
}

.vertical-line,
.view-vertical-line {
  border-left: 1px solid #638be3;
  height: 600px;
  width: 0;
  margin-top: 48px;
}

.view-vertical-line {
  height: 312px;
}

.edit-info {
  display: flex;
  flex-direction: column;
  margin: 2rem;
  overflow-y: auto;
}

.edit-title {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  line-height: 16px;
}

.edit-title p {
  padding-left: 2%;
  padding-top: 0.5%;
}

.label-input {
  display: flex;
  flex-direction: column;
}

input[type='password'] {
  width: 60%;
}

.username-password-input {
  width: 95%;
  height: 29px;
  border: 1.5px solid #cedcf2;
  border-radius: 5px;
  background-color: #edf3fc;
  padding-left: 5%;
}

.password-input {
  height: 29px;
  border: 1.5px solid #cedcf2;
  border-radius: 5px;
  background-color: #edf3fc;
  padding-left: 5%;
}

.email-input {
  width: 98%;
  height: 29px;
  border: 1.5px solid #cedcf2;
  border-radius: 5px;
  background-color: #edf3fc;
  padding-left: 5%;
}

.mfa-input {
  width: 70%;
  border: 1.5px solid #cedcf2;
  border-radius: 5px;
  background-color: #edf3fc;
}

.change-password,
.save-button,
.cancel-button {
  color: #4f76db;
  border: 1.5px solid #4f76db;
  border-radius: 5px;
  font-size: 12px;
  padding: 2%;
  cursor: pointer;
  background-color: #fff;
}

.save-cancel {
  display: flex;
  justify-content: flex-end;
}

.cancel-button {
  width: 25%;
}

.save-button {
  background-color: #4f76db;
  color: white;
  margin-right: 2%;
}

.close {
  position: absolute;
  right: -90px;
  top: 5px;
}

.edit-profile-label {
  font-size: 14px;
}

.profile-label {
  font-size: 12px;
}

@media (max-width: 868px) {
  .view-profile,
  .edit-profile {
    height: 100%;
    flex-direction: column;
    padding: 1rem;
  }
  .view-vertical-line,
  .vertical-line {
    display: none;
  }
}

.PhoneInputInput {
  border: 1.5px solid #cedcf2;
  border-radius: 5px;
  background-color: #edf3fc;
  padding-left: 5%;
  margin-left: 0.2em;
  margin-top: 0.1em;
  padding-top: 0.3em;
}

.PhoneInputCountrySelect {
  width: 90%;
  border: 1.5px solid #cedcf2;
  border-radius: 5px;
  background-color: #edf3fc;
}

.PhoneInputCountryIconImg {
  float: left;
  margin-top: 0.4em;
}

@import '../../../sass/variables.scss';

#editUserBtn {
  background-color: $lightBgColor;
  border: none;
  outline: none;
  margin-top: 8px;
  padding: 0px 20px 0px 20px;
}

#deleteUserBtn {
  background-color: $lightBgColor;
  border: none;
  margin-bottom: 5px;
  padding: 10px 20px 0px 20px;
}

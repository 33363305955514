.alertInputContainer {
  margin-right: 24px;
}

.notificationOption {
  margin-top: 0.5em;
  margin-right: 1em;
  color: #000;
}

.notificationCheckbox {
  margin-right: 0.5em;
}

.descriptionValue {
  color: #495057 !important;
  font-size: 80%;
  margin-bottom: 0.75rem;
}

.notificationContainer {
  margin-bottom: 0.8em;
}

.notificationErrors {
  color: #dc3545;
  font-size: 80%;
}

.alertFieldLabel {
  opacity: 0.6;
  color: #000;
  font-family: Hind;
  font-size: 12px;
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
}

@import './variables.scss';
@import './misc.scss';

.appBtn {
  font-size: 12px;
  font-weight: bold;
  border-radius: 5px;
  word-wrap: break-word;
  cursor: pointer;
  text-align: center;
  height: 36px;
  line-height: 36px;
  width: 120px;
}

.selectedBtn {
  background-color: $highlightFontColor;
  color: $bgColor;
}

.clearBtn {
  color: $highlightFontColor;
  border: 1px solid $highlightFontColor;
  background-color: $lightBgColor;
}

.disabledBtn {
  background-color: $clearBtnBg;
  color: $lightFontColor;
}

@mixin gradientButton($width, $colorLeft, $colorRight) {
  border-radius: 40px;
  box-sizing: border-box;
  color: $lightFontColor;
  height: 38px;
  margin-right: 10px;
  padding: 3px;
  width: $width;
  z-index: z('button');
  background-image: linear-gradient(to right, $colorLeft 0%, $colorRight 100%);
  position: relative;
  display: inline-block;
  overflow: hidden;
  transition: all 0.5s;
  border: none;
  text-transform: uppercase;

  &:focus {
    box-shadow: none;
    outline: none;
  }
  &:hover,
  &[showFilter='true'] {
    color: $boldFontColor;
    span {
      background: transparent;
    }
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    background-image: linear-gradient(
      to right,
      $colorLeft 0%,
      $colorRight 100%
    );
    z-index: z('button-before');
    transform: translate3D(0, -100%, 0);
    transition: all 0.5s;
  }
  &:before {
    background-image: linear-gradient(
      to right,
      $colorLeft 0%,
      $colorRight 100%
    );
    z-index: z('button-before');
    transform: translate3D(0, 0, 0);
  }
  &:hover:after {
    transform: translate3D(0, 0, 0);
    transition: all 0.5s;
  }

  span {
    align-items: center;
    background: $lightBgColor;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-size: $fontSize;
  }
}

.btnAction {
  @include gradientButton(200px, #2b5876, #4e4376);
}

.btnFilter {
  border-radius: 5px;
  color: white;
  height: 36px;
  margin-left: 10px;
  padding: 3px;
  width: 120px;
  z-index: z('button');
  position: relative;
  display: inline-block;
  overflow: hidden;
  border: none;
  align-items: center;
  background: #2557d7;
  font-size: 12px;
  font-family: 'Hind', sans-serif;
  span {
    line-height: 19px;
    height: 100%;
    width: 100%;
  }
}

.btnApplyFilter {
  @include gradientButton(160px, #006175, #00a950);
}

.btnClearFilter {
  @include gradientButton(160px, #485563, #29323c);
}

@import '../../sass/variables.scss';
@import '../../sass/misc.scss';

.headerBar {
  position: fixed;
  z-index: z('header');
  top: 0;
  height: 4.4rem;
  background: $lightBgColor;
  transition: 0ms;
  padding-left: 100px;
  border-bottom: 1px solid $lightBorderColor;
  box-shadow: -1px 1px 5px -2px $lightBorderColor;
  width: 100%;

  .fixedTop {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    flex-direction: row !important;
    height: 4.4rem;
    &[expanded='true'] {
      width: 90%;
    }
    &[expanded='false'] {
      width: 100%;
    }
  }

  #headerbar {
    position: relative;
    display: flex;
    flex-direction: row;
  }

  #logo {
    padding-top: 1rem;
    margin-right: auto;
    left: 0;
    width: 23%;
  }

  #userInfo {
    height: 4.4rem;
    padding: auto 0px;
    border: none;
    background-color: $lightBgColor;
    border-bottom: 1px solid $lightBorderColor;
    box-shadow: -1px 1px 5px -5px $lightBorderColor;
    width: 100%;
    max-width: 9rem;

    #greetingText {
      padding-right: 0.5rem;
      color: $boldFontColor;
      font-size: 12px;
      white-space: nowrap;
      width: 9rem;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
    }
    .itemIcon {
      width: 35px;
    }
    &:focus {
      outline: 0;
    }
  }

  .headerBarItem {
    display: flex;
    flex-direction: row;
    margin: 22px 0px;
    padding: 0px;

    .itemTitle {
      width: 120px;
      margin: 0px 10px;
      font-size: 12px;
    }
    .itemValue {
      font-size: 14px;
      color: #000;
    }
  }

  .itemIcon {
    width: 28px;
    height: auto;
    object-fit: cover;
    opacity: 0.4;
  }

  .headerBarDetailList {
    display: flex;
    flex-direction: column;
    background-color: $lightBgColor;
    position: absolute;
    top: 70px;
    right: calc(100% - 91%);

    p {
      font-size: 12px;
      line-height: 24px;
      padding-left: 10px;
      margin: 0px;
    }
    p:hover {
      font-weight: bold;
      color: $highlightFontColor;
    }

    .headerBarItem {
      width: 104px;
      height: 24x;
      margin: 10px 20px 5px 20px;
      cursor: pointer;
    }
  }
  .dropdownIcon {
    width: 14px;
    height: 14px;
    margin-left: 20px;
  }
  .logoFullMobile {
    display: none;
  }
  .hamburgerMenu {
    display: none;
  }
}

.profilePictureHeader {
  width: 40px;
  height: 40px;
}

@media (max-width: 1648px) {
  .headerBar {
    padding-left: 50px;
  }
  .headerBar .fixedTop {
    &[expanded='true'] {
      width: 78%;
    }
    &[expanded='false'] {
      width: 95%;
    }
    .headerBarDetailList {
      right: 2rem;
    }
  }
}

@media (max-width: 1090px) {
  .headerBar .fixedTop {
    &[expanded='true'] {
      width: 72%;
    }
    &[expanded='false'] {
      width: 82%;
    }
  }
}

@media (max-width: 868px) {
  .headerBar .fixedTop {
    &[expanded='true'] {
      width: 60%;
    }
    &[expanded='false'] {
      width: 75%;
    }
    #headerBarStats {
      display: none;
    }
    .headerBarDetailList {
      right: -70px;
    }
  }
  .headerBar {
    padding: 0;
    .logoFullMobile {
      height: 39px;
      display: block;
      margin: auto 0 auto 5rem;
    }
    .appBtn.selectedBtn {
      display: none;
    }
    .hamburgerMenu {
      display: flex;
    }
  }
  #editTopbar {
    display: none;
  }
}

@media (max-width: 868px) and (orientation: landscape) {
  .headerBar {
    .logoFullMobile {
      margin: auto 11rem auto 16rem;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .headerBar {
    .logoFullMobile {
      margin: auto 11rem auto 16rem;
    }
  }
}

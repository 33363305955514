@import '../../sass/variables.scss';
@import '../../sass/misc.scss';

#mapContainer {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: $fontSize;

  .controlBtn {
    visibility: hidden;

    #backBtn {
      width: 100px;
      margin: 0 25%;
    }
  }

  #viewContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    margin: 0 auto;

    .portContainer {
      display: flex;
      flex-direction: column-reverse;
      position: relative;
      margin: 10px;
    }
    #tooltip {
      width: 180px;
      margin: auto;
    }
  }
}

@import '../../sass/variables.scss';

.adminContainer {
  box-sizing: border-box;
  height: 804px;
  margin-top: 2em;
}

/* Inactive tab */
.nav-link {
  background-color: #f7f7f7;
  box-sizing: border-box;
  height: 36px;
  width: 193px;
  border-radius: 5px 5px 0 0;
  border: 1px solid #4f76db !important;
  color: #000;
  font-size: 14px;
  text-align: center;
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs {
  border-bottom: none;
  font-size: 14px;
}

/* Active tab */
.nav-link.active,
.nav-link.active:hover,
.nav-link.active:focus {
  height: 35.51px;
  width: 192px;
  border-bottom: none !important;
  border-radius: 5px 5px 0 0;
  background-color: #e6ecf5 !important;
  color: #4f76db !important;
  font-weight: 700;
  opacity: 1;
}

.nav-tabs .nav-link.disabled {
  background-color: #f7f7f7 !important;
  font-family: Hind, 'sans-serif' !important;
}

.adminContainerHeader {
  height: 96px;
}

.userCardContainer {
  float: right;
}

.userCard {
  height: 96px;
  width: 120px;
  border-radius: 5px;
  background-color: #fff;
  display: inline-block;
  margin-right: 12px;
}

.userCardLabel {
  padding-top: 10px;
  padding-left: 12px;
  width: 10px;
  opacity: 0.6;
  color: #000;
  font-family: Hind;
  font-size: 13px;
}

.userCardValue {
  color: #000;
  font-family: Roboto;
  font-size: 22px;
  text-align: center;
  padding-top: 8px;
}

#adminLabel {
  margin-bottom: 20px;
  width: 205px;
  color: #000;
  font-family: Hind;
  font-size: 30px;
  display: inline-block;
  padding-top: 33px;
}

.adminUI {
  margin-top: 48.94px;
  width: 1272px;
  margin: 48.94px auto 0px auto;
}

.filterContainer {
  height: 100%;
  width: 100%;
  border: 1px solid #4f76db;
  border-bottom: none;
  background-color: #e6ecf5;
  border-top-right-radius: 5px;
  padding-bottom: 20px;
}

.tableContainer {
  border-radius: 0 0 5px 5px;
  background-color: #fff;
  font-family: Hind, 'sans-serif';
  border: none;
  font-size: 12px;
  color: #000;
  border: 1px solid #4f76db;
  border-top: none;
  position: relative;
}

.searchInputContainer {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  .filterAddBtn {
    position: absolute;
    top: 1.5rem;
    right: 2rem;
    height: 36px;
    width: 120px;
    border-radius: 5px;
    background-color: #4f76db;
    color: #fff;
    font-family: Hind;
    font-size: 12px;
    font-weight: 700;
    line-height: 19px;
    border: none;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 411px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .searchInputContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    .filterAddBtn {
      position: relative;
      top: 0;
      right: 0;
      margin: 0.5rem 0 0 1.3rem;
    }
  }
}

.workerStatsItemValue {
  margin-top: 0.5rem;
}

#blockseerPoolHashrateContainer {
  display: flex;
  .content1 {
    width: 20rem;
    border-right: 1px solid rgba(0, 0, 0, 0.3);
    padding-right: 4rem;
  }
  .content2 {
    padding-left: 2rem;
    .infoBoxValue {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      height: 19rem;
    }
  }
}

@media (max-width: 868px) {
  #blockseerPoolHashrateContainer {
    flex-direction: column;
    .content1 {
      max-width: 19rem;
      border-right: none;
    }
    .content2 {
      padding: 0;
      margin-top: 3rem;
      .infoBoxValue {
        height: 12rem;
      }
    }
  }
}

@import '../../sass/variables.scss';

#forget-password {
  margin: 10% auto;
  width: $loginWidth;
  text-align: center;

  .input-fields {
    margin: 20px auto;
    width: $loginWidth;

    #email {
      border: 2px solid $lightBorderColor;
      background-color: $lightBgColor;
    }

    .link {
      margin: 3% auto;
      color: $lightFontColor;
    }
  }

  .loginButton {
    background-color: $highlightFontColor;
    color: $bgColor;
    padding: 10px 20px;
    border-radius: 5px;
    word-wrap: break-word;
    width: $loginWidth;
    font-size: 18px;
    margin-top: 72px;
  }

  .loginButton:disabled {
    background-color: rgba(160, 167, 179, 0.2);
    border: none;
    color: #000;
    opacity: 0.4;
  }

  .logoFullRestPasswordForm {
    width: 80%;
    display: block;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
}

@import '../../sass/variables.scss';

#container {
  border: 1px solid #4f76db !important;
  width: 1272px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.filter_container {
  border: none;
  height: 122px;
  background-color: #e6ecf5;
  width: 100%;
}

.table_container {
  border-radius: 0 0 5px 5px;
  background-color: #fff;
  font-family: Hind, 'sans-serif';
  font-size: 12px;
  color: #000;
}

.worker_data_container {
  margin-left: 24px;
  height: 19px;
  width: 144px;
  opacity: 0.6;
  color: #000;
  font-family: Hind;
  font-size: 12px;
  margin-top: 24px;
}

.worker_table_container {
  box-sizing: border-box;
  height: 156px;
  margin-top: 36px;
}

.worker_stats_count_container {
  margin-top: 84px;
  display: flex;
}

.worker_stats_count {
  display: flex;
  height: 8.5em;
  width: 40em;
  border: 1px solid #4f76db;
  background-color: $lightBgColor;
}

.worker_count {
  width: 20%;
  height: 100%;
  margin-top: 1em;
}

#worker_stat_count_label {
  height: 24.12px;
  width: 100%;
  color: #4f4f4f;
  font-family: Hind;
  font-size: 0.7em;
  text-align: center;
  margin-bottom: 0;
}

#worker_stat_count_value {
  height: 33px;
  width: 100%;
  color: #000;
  font-family: Roboto;
  font-size: 28px;
  text-align: center;
  margin-top: 0.6em;
}

#worker_stat_count_color {
  height: 8px;
  width: 50px;
  border-radius: 5px;
  margin: auto;
}

.worker_stats_hashrate_container {
  height: 108px;
  width: 324px;
  margin-left: 24px;
  border: 1px solid #4f76db;
}

.worker_stats_revenue_container {
  height: 108px;
  width: 324px;
  margin-left: 12px;
  border: 1px solid #4f76db;
}

#worker_stat_label {
  height: 22px;
  width: 156px;
  opacity: 0.6;
  color: #000;
  font-family: Hind;
  font-size: 14px;
}

.worker_stat_container {
  width: 100%;
  height: 82px;
  display: flex;
}

.worker_stat {
  width: 33.33%;
  height: 100%;
}

#worker_stat_time_label {
  height: 16px;
  text-align: center;
  opacity: 0.6;
  color: #000;
  font-family: Hind;
  font-size: 10px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}

#worker_stat_time_value {
  height: 14px;
  text-align: center;
  color: #000;
  font-family: Roboto;
  font-size: 12px;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  opacity: 1 !important;
}

.search_input_container {
  display: flex;
  flex-wrap: wrap;
}

.sub_component_container {
  margin: 15px 105px;
  z-index: 3;
}

.ReactTable * {
  background: #fff !important;
}

.ReactTable {
  height: 156px;
}

.ReactTable .rt-table {
  overflow-y: scroll !important;
}

.ReactTable .rt-thead {
  background: #fff !important;
  color: #000 !important;
  font-family: Hind, 'sans-serif';
  font-weight: 700 !important;
  font-size: 12px;
  padding: 0 !important;
}

.ReactTable .rt-tr .rt-td {
  margin: 0 !important;
  margin-top: 5px !important;
}

.ReactTable .rt-tr .rt-th {
  margin: 0 !important;
  margin-top: 10px !important;
}

.ReactTable .rt-thead .rt-th {
  border: none !important;
}

.ReactTable .rt-thead .rt-resizable-header {
  overflow: hidden !important;
}

.ReactTable .rt-tr {
  border: none !important;
}

.ReactTable .rt-thead .rt-tr {
  width: 100%;
}

.ReactTable .rt-td {
  font-family: Roboto, 'sans-serif';
  font-size: 12px;
  opacity: 0.6;
}

.ReactTable .rt-tbody .rt-td {
  border: none;
  margin-left: 0;
}

.ReactTable .rt-tbody .rt-tr-group {
  border: none;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: none !important;
}
.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: none !important;
}

.minemanagementButtonsSection {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  margin-top: 70px;
}

.input_box {
  margin-top: 37px;
  margin-left: 24px;
  width: 252px;
  height: 36px;
  box-sizing: border-box;
  border: 1.5px solid #cedcf2;
  border-radius: 4px;
  background-color: #fff;
  background-image: url('/tableSearch.png');
  background-size: 18px;
  background-position: 15px 7.5px;
  background-repeat: no-repeat;
  padding: 15px 20px 12px 45px;
  color: #000;
  font-family: Hind;
  font-size: 12px;
  line-height: 19px;
}

.ReactTable .rt-resizer {
  display: none !important;
}

.btn_action {
  height: 3.3em;
  width: 11em;
  border-radius: 0.6em;
  background-color: #4f76db;
  color: #fff;
  font-family: Hind;
  font-size: 0.7em;
  font-weight: 700;
  border: none;
  margin-left: 1.1em;
  margin-top: 3.2em;
}

.btn_delete {
  height: 3.3em;
  width: 9em;
  border-radius: 0.6em;
  background-color: red;
  color: #fff;
  font-family: Hind;
  font-size: 0.7em;
  font-weight: 700;
  border: none;
  margin-left: 1.1em;
  margin-top: 3.2em;
}

.btn_graph:disabled {
  height: 36px;
  background-color: #e6e7ea;
  line-height: 19px;
  text-align: center;
  border: none;
  color: #000;
  font-family: Hind;
  font-size: 12px;
  font-weight: normal;
  opacity: 0.6;
  margin-left: 492px;
  border-radius: 5px;
  line-height: 19px;
  margin-top: 37px;
}

.dropdown_container {
  &:hover .dropdown-content {
    display: block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3) inset;
    padding: 0px 5px;
    z-index: 5;
    outline: none;
    border-radius: 5px;
    white-space: nowrap;
    border: 1px solid #424351;
    margin-left: 10px;

    p {
      padding: 5px 10px;
      margin: 5px 0px;
      cursor: pointer;
      font-size: 14px;
      &:hover {
        color: #5184d9;
      }
    }
  }
}

#filter_label {
  height: 17px;
  width: 40px;
  opacity: 0.5;
  color: #000;
  font-family: Hind;
  font-size: 17px;
  font-weight: 700;
  line-height: 17px;
}

.filter_input {
  display: flex;
  float: right;
  margin-top: 12px;
}

.switch_button {
  margin-left: 10px;
  top: 5px;
}

.switch_text_button {
  display: inline-block;
  margin: 20px 40px 0px 10px;
  font-size: 15px;
}

.minemanagementFiltersSection {
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px;
  clear: both;

  .minemanagementFilter {
    min-width: 270px;
    margin-bottom: 0px;
    padding: 0px;
    margin-right: 0px;
  }
  .minemanagementFilterLabel {
    margin-bottom: 5px;
    font-size: 14px;
  }
  .minemanagementFilterActions {
    width: 100%;
    flex: 1 0 1;
    display: flex;
    justify-content: flex-end;
  }
  .css-kj6f9i-menu {
    z-index: 4 !important;
    background: $lightBgColor !important;
    border: 1px solid #424351 !important;
    font-size: 12px !important;
  }
  .css-es53b3-multiValue {
    background: #cbcce2 !important;
  }
  .css-151xaom-placeholder {
    font-size: 14px !important;
  }
  .css-1pcexqc-container {
    width: 90% !important;
  }
}

#miner_count {
  margin-top: 20px;
  font-weight: bolder;
  color: $boldFontColor;
  letter-spacing: 0.6px;
}

.worker_container {
  width: 1272px;
  margin: 0px auto;
  .tagContainer {
    .filterTag {
      margin-top: 10px;
    }
  }
}
.filters {
  padding: 0px !important;
  #filterInput {
    position: relative;
  }
}

@import '../../sass/variables.scss';
@import '../../sass/misc.scss';

.bgCover {
  background-color: #e6e7ea;
  opacity: 0.5;
  position: absolute;
  top: 0px;
  z-index: z('header-coverBg');
  height: 100%;
  width: 100%;
}

.optionsMenu {
  position: absolute;
  top: -17px;
  z-index: z('header-optionsList');
  background-color: $lightBgColor;
  padding: 10px 0px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid $selectedBorderColor;
  color: $boldFontColor;
  div {
    padding: 3px 24px;
    cursor: pointer;
  }
  div:hover {
    background-color: $hoverBgColor;
  }
}

@import '../../sass/variables.scss';

:root {
  --display-export-btn: none;
}

.exportBtn {
  font-size: 12px;
  font-weight: bold;
  border-radius: 5px;
  word-wrap: break-word;
  cursor: pointer;
  text-align: center;
  height: 36px;
  line-height: 36px;
  width: 140px;
  background-color: $highlightFontColor;
  color: $bgColor;
  border: none;
}

.exportBtnContainer:hover {
  --display-export-btn: block;
}

.reportExportMenu {
  position: absolute;
  z-index: 1;
  background: white;
  border: 1px solid $highlightFontColor;
  padding: 10px 10px 3px 10px;
  width: 140px;
  font-size: 12px;
  color: $fontColor;
  display: var(--display-export-btn);

  .reportMenuItem {
    margin-bottom: 7px;
    cursor: pointer;
  }

  .reportMenuItem:hover {
    color: #5184d9;
  }
}

@import '../../../sass/variables.scss';

.filters {
  margin-top: 6px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
  border-radius: 4px;
  clear: both;
}

#filterBtn {
  height: 36px;
  width: 120px;
  border-radius: 5px;
  background-color: #4f76db;
  color: #fff;
  font-family: Hind;
  font-size: 12px;
  font-weight: 700;
  line-height: 19px;
  border: none;
  margin-right: 12px;
}

#filterInput {
  box-sizing: border-box;
  height: 36px;
  width: 216px;
  border-radius: 5px;
  background-color: #fff;
  margin-right: 12px;
  font-size: 12px;
  margin-bottom: 10px;
}

#filterInput * {
  box-shadow: none;
}

#filterLabel {
  margin-bottom: 5px;
  font-size: 14px;
}

#filterBtn:disabled {
  background-color: #e6e7ea;
  line-height: 19px;
  text-align: center;
  border: none;
  color: #000;
  font-weight: normal;
  opacity: 0.6;
}

#filterHeader {
  margin-top: 13px;
  margin-left: 24px;
  height: 17px;
  width: 29px;
  opacity: 0.5;
  color: #000;
  font-family: Hind;
  font-size: 12px;
  font-weight: 700;
  border-top: none;
}

.inputBoxForm {
  border: none !important;
}

.inputBox {
  margin-top: 24px;
  margin-left: 20px;
  width: 252px;
  height: 36px;
  box-sizing: border-box;
  border: 1.5px solid #cedcf2;
  border-radius: 4px;
  background-color: #fff;
  background-image: url('/tableSearch.png');
  background-size: 18px;
  background-position: 15px 7.5px;
  background-repeat: no-repeat;
  padding: 15px 20px 12px 45px;
  color: #000;
  font-family: Hind;
  font-size: 12px;
  line-height: 19px;
}

.inputBox:focus {
  outline: none;
}

#filterAddUserBtn {
  position: absolute;
  top: 2.2rem;
  right: 2rem;
  height: 36px;
  width: 120px;
  border-radius: 5px;
  background-color: #4f76db;
  color: #fff;
  font-family: Hind;
  font-size: 12px;
  font-weight: 700;
  line-height: 19px;
  border: none;
}

#filterAddUserBtn:disabled {
  background-color: $clearBtnBg;
  color: $lightFontColor;
}

.ReactTable * {
  background: #fff !important;
}

.ReactTable {
  height: 100%;
}

.ReactTable .rt-table {
  position: relative;
  overflow-y: scroll !important;
}

.ReactTable .rt-thead {
  background: #fff !important;
  color: #000 !important;
  font-family: Hind, 'sans-serif';
  font-weight: 700 !important;
  font-size: 12px;
}

.ReactTable .rt-tr .rt-th {
  margin-left: 0 !important;
}

.ReactTable .rt-thead .rt-th {
  border: none !important;
}

.ReactTable .rt-thead .rt-resizable-header {
  overflow: hidden !important;
}

.ReactTable .rt-tr {
  border: none !important;
}

.ReactTable .rt-td {
  font-family: Roboto, 'sans-serif';
  font-size: 12px;
  opacity: 0.6;
}

.ReactTable .rt-tbody .rt-td {
  border: none;
  margin-left: 0;
}

.ReactTable .rt-tbody .rt-tr-group {
  border: none;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: none !important;
}
.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: none !important;
}

#sortIcon {
  height: 11.31px;
  width: 11.31px;
  margin-right: 5px;
}

#actionRowBtn {
  height: 13.5px;
  width: 16.2px;
  cursor: pointer;
}

.ReactTable .-pagination input {
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #fff;
  border: 0.5px solid #4f76db;
  font-family: Hind, 'sans-serif';
  color: #4f76db !important;
  font-weight: 700;
}

.ReactTable .-pagination select {
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #fff;
  border: 0.5px solid #4f76db;
  font-family: Hind, 'sans-serif';
  color: #000;
}

.ReactTable .-pagination .-btn {
  color: #000;
  font-family: Hind, 'sans-serif';
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
  color: #4f76db !important;
  font-weight: 700;
}

.menu {
  position: absolute;
  box-sizing: border-box;
  height: 59px;
  width: 120.08px;
  border-radius: 5px;
  background-color: #fff;
  border-color: #5184d9 !important;
  margin-top: 5px;
  margin-left: -75px;
  overflow: visible;
  z-index: 1000;
}

#editRowBtn {
  display: block;
  z-index: 100;
  color: #000;
  font-family: Hind;
  font-size: 12px;
  width: 100%;
  height: 28.5px;
  background-color: #fff;
  border: none;
}

.editUserSubMenu {
  border: 1px solid #5184d9;
  position: absolute;
  right: 45px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

@import '../../sass/variables.scss';
@import '../../sass/misc.scss';

.chartsSection {
  display: flex;
  flex-direction: row;
  width: 1300px;
  margin: 0px auto;

  .filteredChartsSection {
    background-color: $darkBgColor;
    display: block;
    .filteredCharts {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 25px;
    }
  }
  .noFilteredCharts {
    display: flex;
    flex-direction: row;
    background-color: $darkBgColor;
    margin: 1rem 1rem 0.5rem 0.5rem;
    padding: 0.6rem 0.7rem 0.7rem 0rem;

    .powerDataCell {
      margin: 160px 10px 15px 0px;

      .unitContainer {
        width: 135px;
        height: 80px;
        background-color: $lightBgColor;
        padding: 13px 15px;
        display: inline-block;
        margin: 10px 10px;

        .unitTitle {
          font-size: $fontSize - 1;
        }
        .unitPowerData {
          font-size: ($fontSize + 11);
          color: $boldFontColor;
          span {
            font-size: ($fontSize);
          }
        }
      }
    }
  }
}

.filterNumber {
  position: absolute;
  right: 50px;
  top: 10px;
  color: $bgColor;
  border-radius: 20px;
  z-index: z('button');
  padding: 0px 10px;
  font-size: 12px;
  font-weight: bold;
}

#customerFilterNumber {
  background-color: #5851d9;
}
#poolFilterNumber {
  background-color: $highlightFontColor;
}
#typeFilterNumber {
  background-color: #76a5c4;
}
#switchFilterNumber {
  background-color: #1a99ea;
}
#locationFilterNumber {
  background-color: #48bddd;
}
#statusFilterNumber {
  background-color: #9e8bff;
}

.css-26l3qy-menu, //no Option box
.css-g1d714-ValueContainer, //search input
.css-1wa3eu0-placeholder, 
.css-yt9ioa-option, 
.css-kyp72j-option, 
.css-cdz9lr-option {
  font-size: 12px !important;
  font-family: $fontFamily;
  color: $fontColor;
}

.css-kyp72j-option {
  background-color: $highlightFontColor !important;
}

.tagContainer {
  display: flex;
  flex-wrap: wrap;

  .filterTag {
    font-size: 12px;
    font-weight: bold;
    padding: 0px 10px;
    border-radius: 15px;
    margin-right: 5px;
    cursor: pointer;
    margin-top: 20px;
    &.customerTag {
      color: #5851d9;
      border: 1px solid #5851d9;
      &[is_filter_applied='true'] {
        background: #5851d9;
        color: white;
      }
      &[is_filter_applied='false'] {
        color: #5851d9;
        background: white;
      }
    }

    &.poolTag {
      color: $highlightFontColor;
      border: 1px solid $highlightFontColor;
      &[is_filter_applied='true'] {
        background: $highlightFontColor;
        color: white;
      }
      &[is_filter_applied='false'] {
        color: $highlightFontColor;
        background: white;
      }
    }

    &.typeTag {
      color: #76a5c4;
      border: 1px solid #76a5c4;
      &[is_filter_applied='true'] {
        background: #76a5c4;
        color: white;
      }
      &[is_filter_applied='false'] {
        color: #76a5c4;
        background: white;
      }
    }

    &.locationTag {
      color: #48bddd;
      border: 1px solid #48bddd;
      &[is_filter_applied='true'] {
        background: #48bddd;
        color: white;
      }
      &[is_filter_applied='false'] {
        color: #48bddd;
        background: white;
      }
    }

    &.switchTag {
      color: #1a99ea;
      border: 1px solid #1a99ea;
      &[is_filter_applied='true'] {
        background: #1a99ea;
        color: white;
      }
      &[is_filter_applied='false'] {
        color: #1a99ea;
        background: white;
      }
    }

    &.statusTag {
      color: #9e8bff;
      border: 1px solid #9e8bff;
      &[is_filter_applied='true'] {
        background: #9e8bff;
        color: white;
      }
      &[is_filter_applied='false'] {
        color: #9e8bff;
        background: white;
      }
    }
  }
}

@media (min-width: 1500px) {
  .chartsSection {
    flex-direction: column;
  }
  .powerDataCell {
    width: 470px;
    margin: auto !important;
  }
  #powerDataChartContainer {
    margin: 5px 25px;
  }
}

@media (max-width: 1499px) {
  .chartsSection {
    flex-direction: column;
    width: 100%;
    margin-left: 100px;
  }
  .filteredCharts {
    flex-direction: column !important;
  }
  .noFilteredCharts {
    flex-direction: column !important;
  }
  .powerDataCell {
    max-width: 470px;
    margin: auto !important;
  }
  #barChartContainer,
  #hashrateChartContainer,
  #powerDataChartContainer {
    margin: 5px auto !important;
  }
  .filterOption {
    min-width: 220px !important;
  }
}

@media (max-width: 868px) {
  .chartsSection {
    margin-left: 0;
  }
}

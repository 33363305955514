#reset-password {
  margin: 20px auto;
  border: 1px solid lightgrey;
  width: 30%;
}

#input-fields {
  margin: 20px auto;
  width: 70%;
  font-family: 'Roboto Condensed', sans-serif;
}

#label {
  margin: auto;
  width: 70%;
  text-align: center;
  font-size: 12px;
  font-family: 'Roboto Condensed', sans-serif;
}

.reset-password-error-message {
  color: red;
  font-size: 12px;
}

.logoFullRestPassword {
  width: 80%;
  display: block;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.legendContainer {
  position: absolute;
  right: 2rem;
  border: 1px solid #b1b1b5;
  font-size: 12px;
  padding: 0.5rem 1rem;
  .legendTitle {
    margin-left: 0.5rem;
  }
}

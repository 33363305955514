@import "./sass/variables.scss";

#customer-login{
    margin: 10% auto;
    width: $loginWidth;
    text-align: left;
    
    h1{
        font-size: 48px;
        line-height: 77px;
        color: $highlightFontColor;
    }

    .input-fields{
        margin: 53px 0px 0px 0px;
        width: $loginWidth;
        
        #username, #password{
            border: 2px solid $lightBorderColor;
            background-color: $lightBgColor;
        }
        #username[invalidLogin=true], #password[invalidLogin=true]{
            border: 2px solid $errorBorderColor;
        }
        #password, #password[invalidLogin=true]{
            border-right: none;
        }
        
        
        .formIcon{
            background: transparent;
            border-right-style: none;
        }

        .link{
            color: $boldFontColor;
            opacity: 0.4 !important;
            margin: 0px;
        }
    }
    
    #label{
        font-size: 12px;
        position: relative;
        margin-top: 3em;
    }

    .loginButton{
        background-color: $highlightFontColor;
        color: $bgColor;
        padding: 10px 20px;
        border-radius: 5px;
        word-wrap: break-word;
        width: $loginWidth;
        font-size: 18px;
        margin-top: 72px;
    }

    .mfaButton{
        background-color: $highlightFontColor;
        color: $bgColor;
        padding: 0.6em 0.8em;
        border-radius: 0.4em;
        word-wrap: break-word;
        width: $loginWidth;
        font-size: 1.0em;
        margin-top: 20%;
    }

    .resendMfaButton {
        font-size: 0.8em;
        position: absolute;
        background-color: transparent !important;
        color: $fontColor !important;
    }
    
    .resendMfaButton:disabled {
        opacity: .30 !important;
    }
    
    .mfaProgressCircle {
        z-index: 1;
        height: 1.2em !important;
        width: 1.2em !important;
    }
    
    .mfaWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .errorText{
        color: $errorFontColor;
        font-size: 14px;
        display: inline-block;
        padding-top: 4px;
    }

    .logoFullLogin{
        width: 80%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        position: relative;
    }

    #passwordHideShowIcon{
        border: 2px solid $lightBorderColor;
        border-left: none;
        background-color: $lightBgColor;
        cursor: pointer;
        img{
            width: 20px;
        }
    }
    #passwordHideShowIcon[validateForm=true]{
        background-color: #e8f0fe;
    }
    #passwordHideShowIcon[invalidLogin=true]{
        border: 2px solid $errorBorderColor;
        border-left: none;
    }
}
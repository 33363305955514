@import '../../sass/variables.scss';
@import '../../sass/misc.scss';

.mapContainer {
  border-radius: 5px;
  border: 1px solid $highlightFontColor;
  background-color: $lightBgColor;
  padding: 20px;
  margin-bottom: 50px;
  width: 1170px;
  color: $darkFontColor;
}

#toggleTemp {
  display: flex;
  justify-content: flex-end;

  .switch_button {
    margin-left: 10px;
  }
  .switch_text_button {
    display: inline-block;
    margin: 0px 40px 0px 10px;
    font-size: 15px;
  }
}

.gridMap {
  width: 1130px;
  text-align: center;
  font-size: 12px;
  margin-top: 4rem;
}

.Phase {
  // background-color: rgba(255,255,255,0.01);
  margin: 5px;
  // border: 1px solid rgba(0,0,0,0.25);
  .Container-Title {
    padding-bottom: 5px;
    // color: 'white';
  }
}

.Area,
.Core-area {
  border: 1px solid $borderColor;
  background-color: $darkerBgColor;
  padding-top: 5px;
  margin: 5px;
  .Container-Title {
    padding: 1px;
  }
}

.Core-area {
  cursor: pointer;
  .Container-Title {
    padding-top: 10px;
  }
}

.Pod {
  padding: 0px;
  .Container-Title {
    margin-left: 5px;
  }
  .Container-Content {
    padding: 0px 5px;
  }
}

.Stack {
  height: 102px;
}

.Container-Content {
  display: grid;
}

.Switch {
  // border: 1px solid #b1b1b5;
  border-radius: 3px;
  width: 100%;
  margin-top: 2px;
}

@import './variables.scss';

.ReactTable {
  .rt-thead {
    font-weight: bold;
    padding: 5px 5px 0 5px;
    color: $boldFontColor;
    background: $darkBgColor;
  }

  .rt-tr {
    border-bottom: 0.5px solid #3c3e50;
    .rt-th,
    .rt-td {
      text-align: left;
      margin: 3px 5px;
    }
    .-sort-asc {
      box-shadow: inset 0 1px 0 0 $highlightColor !important;
    }
    .-sort-desc {
      box-shadow: inset 0 -1px 0 0 $highlightColor !important;
    }
  }
}

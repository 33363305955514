.rt-td.alertName {
  padding-left: 1.2rem;
}

#enabledIcons {
  color: black !important;
  padding-left: 2.25em;
}

.form-check-input {
  margin-right: 0 !important;
  margin-bottom: 0 !important;
  margin-top: 0.3rem !important;
  margin-left: -1.25rem !important;
}

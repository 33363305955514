@import '../../sass/variables.scss';

.value {
  color: $boldFontColor;
  font-family: 'Roboto';
  font-size: 0.9rem;
}

.valueGroup {
  margin-right: 10rem;
}

.mainValue {
  font-size: 2rem;
}

.mainLabel {
  margin-top: 3.5rem;
}

.reportLink {
  color: $highlightFontColor !important;
  text-decoration: underline !important;
  cursor: pointer;
  float: right;
}

.reportLink::after {
  clear: both;
}

.flexRow {
  display: flex;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexSpaceBetween {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.4rem;
}

.downValue {
  color: $errorFontColor;
  font-weight: bold;
}

@media (max-width: 868px) {
  .valueGroup {
    margin-right: 0;
  }
}

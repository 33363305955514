@import './variables.scss';

.minerChevron {
  cursor: pointer;
  font-size: $fontSize;
  text-align: center;
  user-select: none;
  color: $lightIconColor;
  margin: 0px 7px;

  &[expanded='true'] {
    transform: rotate(180deg);
    transition: 0.5s;
  }
  &[expanded='false'] {
    transform: rotate(90deg);
    transition: 0.5s;
  }

  &[showfilter='false'] {
    transform: rotate(0deg);
    transition: 0.5s;
  }

  &[showfilter='true'] {
    transform: rotate(180deg);
    transition: 0.5s;
  }
}

@import '../../../sass/variables.scss';

.infoBox.graphBox {
  width: 80rem;
  padding: 2.5rem 2rem 0px 2rem;
}

.datePickerTitle {
  color: $highlightFontColor;
  font-weight: bold;
}

.graphTime {
  width: 100px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border: 2px solid #edf3fc;
  font-size: 14px;
  color: $fontColor;
  cursor: pointer;

  &[selectedGraphTime='true'] {
    color: $highlightFontColor;
    background-color: #edf3fc;
    font-weight: bold;
  }
}

#poolWorkerGraph {
  width: 1250px;
  height: 550px;
  svg {
    width: 100%;
    height: 100%;
    margin: 0px 15px;
  }

  text {
    font-size: 12px;
  }

  #noDataWorkerGraph {
    text-align: center;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .label {
    font-size: 14px;
    fill: $fontColor;
  }

  .label2 {
    font-size: 14px;
    fill: 'black';
  }

  .line {
    fill: none;
    stroke-width: 2px;
  }

  .lineShadow {
    fill: none;
    stroke-width: 2px;
  }

  .mouse-line-worker {
    stroke: #d6d6d6;
    stroke-width: 2;
    stroke-dasharray: 7 2;
  }

  #tooltipWorkerLineChart {
    position: absolute;
    font-size: 12px;
    color: $darkFontColor;
    padding: 15px 20px;
    border-radius: 5px;
    width: 188px;
    height: 83px;
    border: 2px solid #5e89eb;
    background-color: #fff;
    .tooltipHashrateValue {
      color: 'black';
      font-size: 22px;
    }
  }

  #tooltipWorker {
    position: absolute;
    font-size: 12px;
    color: $fontColor;
    padding: 10px 20px;
    border-radius: 5px;
    width: 188px;
    background-color: rgba(237, 243, 252, 0.6);
    height: 60px;
    .tooltipHashrateValue {
      color: 'black';
      font-size: 14px;
    }
  }

  #tooltipBlocksFoundWorker {
    position: absolute;
    font-size: 12px;
    color: $darkFontColor;
    padding: 10px 20px;
    border-radius: 5px;
    width: 188px;
    max-height: 150px;
    border: 2px solid #fae18a;
    background-color: #fff;
    overflow: auto;
    .tooltipHashrateValue {
      color: 'black';
      font-size: 13px;
    }
    .tooltipHashrateBlocksValue {
      padding-left: 1em;
    }
  }

  .grid line {
    stroke: lightgrey;
    stroke-opacity: 0.6;
    shape-rendering: crispEdges;
  }
  .grid path {
    stroke-width: 0;
  }
}

#poolWorkerGraph svg,
#poolGraph svg {
  width: 1224px;
  height: 500px;
}

.legend {
  display: flex;
  flex-direction: row;
  text-align: center;
  margin: 0 auto 20px auto;
  width: 800px;
  color: $darkFontColor;
  font-size: 14px;
  div {
    width: 100%;
  }
  input {
    margin-left: 10px;
  }
  .dot {
    height: 13px;
    width: 13px;
    border-radius: 50%;
    margin-right: 10px;
    display: inline-block;
  }
}

.graphOptionsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 1.4rem;
  .datepickerOptions {
    display: flex;
  }
  .intervalOptions {
    display: flex;
    margin-top: 1.4rem;
  }
}

@media (max-width: 868px) {
  .graphOptionsContainer {
    flex-direction: column;
  }
  .legend {
    width: 40rem;
  }
  #poolWorkerGraph,
  #poolGraph {
    width: 40rem;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

//phone portrait
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .graphOptionsContainer {
    .datepickerOptions {
      flex-direction: column;
    }
  }
  .legend {
    flex-direction: column;
    max-width: 10rem;
  }
  #poolWorkerGraph,
  #poolGraph {
    width: 15rem;
  }
}
